import React, { useState } from 'react';
import styles from './passwordProtectedPage.module.css'; 
import { PrimaryButton } from '../components';
const PasswordProtectedPage = ({ children }) => {
  const [inputPassword, setInputPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const correctPassword = 'MALERBA40'; 

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputPassword === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert('Password sbagliata');
    }
  };

  if (isAuthenticated) {
    return <>{children}</>;
  }

  return (
    <div className={styles.container}>
      <h1 className={styles.heading}>Questo è un listing privato😎</h1>
      <h1 className={styles.subHeading}>Inserisci la password che ti è stata mandata. Grazie</h1>
      <h1 className={styles.subHeading}>💙 Clubjoy 💙</h1>
      <form onSubmit={handleSubmit} className={styles.form}>
        <input
          type="password"
          className={styles.input}
          value={inputPassword}
          onChange={(e) => setInputPassword(e.target.value)}
        />
        <div className={styles.buttonGroup}>
        <PrimaryButton>Accedi</PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default PasswordProtectedPage;
